
import { defineComponent } from 'vue'
import { PrimaryButton } from '@/components/buttons'
import { MenuButton, Accordion, AccordionElement } from '@/components'
import { AddEditDocumentModalDialog, AddEditCommunicationDialog } from '@/components/dialogs'
import { createNamespacedHelpers } from 'vuex'
import { ICompanyCommunicationsStoreState, IDocumentsStoreState } from '@/interfaces'
import { DocumentScope } from '@/enums'
const communicationsHelper = createNamespacedHelpers('communications')
const documentsHelper = createNamespacedHelpers('documents')

export default defineComponent({
  name: 'Dashboard',

  components: {
    MenuButton, PrimaryButton, AddEditDocumentModalDialog, AddEditCommunicationDialog, Accordion, AccordionElement
  },

  data () {
    return {
      isShowingAddEditDocumentDialog: false,
      isShowingAddEditCommunicationDialog: false,
      isActive: false,
      accordionRows: [
        {
          id: 'documents',
          text: 'Documentazione Generale'
        },
        {
          id: 'communications',
          text: 'Comunicazioni Generali'
        },
        {
          id: 'archived',
          text: 'Archivio Comunicazioni Generali'
        }
      ],
      DocumentScope
    }
  },

  computed: {
    ...communicationsHelper.mapState<ICompanyCommunicationsStoreState>({
      activeCommunications: (state: ICompanyCommunicationsStoreState) =>
        state.communications.filter(c => c.isArchived === false),
      archivedCommunications: (state: ICompanyCommunicationsStoreState) =>
        state.communications.filter(c => c.isArchived !== false)
    }),
    ...documentsHelper.mapState<IDocumentsStoreState>({
      documents: (state: IDocumentsStoreState) => state.documents
    })
  },

  /**  Bind Vuexfire on client-side: */
  async mounted () {
    try {
      await this.$store.dispatch('communications/fetchCommunications')
      await this.$store.dispatch('documents/fetchDocuments', { scope: DocumentScope.companyDocument })
    } catch (e) {
      console.error('error getting the company communications')
      console.error(e)
    }
  }
})
